import Vue from 'vue'

function getFileStorage(arquivo) {
  return Vue.prototype.$storage.get(`/storage/url/${arquivo._id}`);
}

async function postFileStorage(arquivo, bucket) {
  validateSizeFile(arquivo.file)

  const formData = new FormData();
  formData.append("file", arquivo.file);

  // Caso não seja enviado o bucket (storageName) passa o defaultBucket (ENVIRONMENT)
  console.log("Environment DEV: ", process.env.VUE_APP_ENVIRONMENT === "dev");
  bucket = process.env.VUE_APP_ENVIRONMENT === "dev" ? process.env.VUE_APP_DEFAULT_BUCKET : bucket || process.env.VUE_APP_DEFAULT_BUCKET

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "bucket": bucket || ""
    },
  };

  return Vue.prototype.$storage.post("/storage/upload", formData, config);
}

function deleteFileStorage(arquivo) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return Vue.prototype.$storage.delete(`/storage/${arquivo._id}`, config);
}

function getStorageFileById(id) {
  return Vue.prototype.$storage.get(`/storage/file/${id}`);
}

function deleteStorageFileById(id) {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return Vue.prototype.$storage.delete(`/storage/${id}`, config);
}

async function validateSizeFile(file) {
  // Controle para permitir arquivos com limite de 20MB
  const maxSize = 20 * 1024 * 1024
  const fileSize = file.size
  
  if (fileSize > maxSize) {
    const maxSizeMB = (maxSize / (1024 * 1024)).toFixed(2)
    const fileSizeMB = (fileSize / (1024 * 1024)).toFixed(2)
    throw new Error(`Arquivo: ${file.name} - ${fileSizeMB} MB é maior que o permitido: ${maxSizeMB} MB`)
  }
  return true
}

export {
  getFileStorage,
  postFileStorage,
  deleteFileStorage,
  getStorageFileById,
  deleteStorageFileById,
  validateSizeFile,
}
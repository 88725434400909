
<template>
  <v-card flat class="mb-2">
    <v-card-title class="primary white--text py-0 px-2">
      <span class="font-weight-regular">{{ labelTitle }}</span>
    </v-card-title>
  </v-card>
</template>

<script>

export default {
  name: "RespostaSubtituloCreateEdit",
  props: {
    labelTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<template>
  <v-container fluid class="px-0">
    <v-card flat>
      <audio id="audioRecord" width="100%" style="max-width: 600px" preload="metadata"></audio>
      <v-card-title class="primary white--text pa-2">
        <span class="font-weight-regular">{{ labelTitle }}</span>
        <v-spacer></v-spacer>
        <v-card v-if="getMediaDevices" color="blue-grey darken-1" height="95%" rounded="pill">
          <span v-if="isRecording" :class="`color:' ${isRecording ? 'red--text' : ''}`" class="body-1 pl-3">
            <strong> {{timer.timeElapsed}} </strong>
          </span>
          <v-btn icon @click="startPauseAudioRecord" :color="isRecording ? 'red' : 'white'">
            <v-icon size="32"> {{iconAudioRecorder}} </v-icon>
          </v-btn>
          <v-btn icon @click="stopMyAudioRecord" v-if="isRecording" small class="pr-3 ml-2" dark>
            <v-icon size="32">mdi-stop-circle-outline</v-icon>
          </v-btn>
        </v-card>
        <v-btn v-if="!isRecording" icon @click="handleFileImport" :loading="isSelecting" dark>
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
        <input 
          accept="audio/*"
          ref="uploaderFile" 
          v-show="false"
          type="file" 
          multiple
          @change="onChangeAudioRespostas"
        >
      </v-card-title>
      
      <!-- <v-toolbar dense dark color="primary" max-height="50">
        <v-toolbar-title>{{ labelTitle }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-card v-if="getMediaDevices" color="blue-grey darken-1" height="95%" rounded="pill">
          <span dark v-if="isRecording" :class="`color:' ${isRecording ? 'red--text' : ''}`" class="pl-3">
            <strong> {{timer.timeElapsed}} </strong>
          </span>
          <v-btn icon @click="startPauseAudioRecord" :color="isRecording ? 'red' : ''">
            <v-icon size="30"> {{iconAudioRecorder}} </v-icon>
          </v-btn>
          <v-btn icon @click="stopMyAudioRecord" v-if="isRecording" small class="pr-3">
            <v-icon size="30">mdi-stop-circle-outline</v-icon>
          </v-btn>
        </v-card>
        <v-btn v-if="!isRecording" icon @click="handleFileImport" :loading="isSelecting" dark>
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
        <input 
          accept="audio/*"
          ref="uploaderFile" 
          v-show="false"
          type="file" 
          multiple
          @change="onChangeAudioRespostas"
        >
      </v-toolbar> -->
    
      <v-card-actions>
        <v-row class="v-row-list-audios" no-gutters>
          <v-col cols="12" v-for="(arquivo, index) in arquivos" :key="index">
            <v-card flat>
              <v-card-text>
                <v-row justify="center" align="center">
                  <audio :id="`audio_resposta_${index}`" class="audio-resposta" controls preload="auto">
                      <source id="audio_resposta_source" :src="`${arquivo.url}`">
                  </audio>
                  <!-- <v-btn icon @click="audioControlsPlayPause(index)">
                    <v-icon> {{ iconAudioReposta }} </v-icon>
                  </v-btn> -->
                  <v-btn icon @click="deletarResposta(arquivo)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <!-- <v-col>
                    <span> {{currentTime}}/{{duration}} </span>
                    <v-progress-linear :id="`audio_time_control_${index}`"
                      v-model="currentTime"
                      :buffer-value="duration"
                    ></v-progress-linear>
                  </v-col> -->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <alerta></alerta>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Alerta from '@/components/comum/Alerta.vue'
import { getFileStorage } from "@/utils/storageUtils"
import { validateSizeFile } from '../../utils/storageUtils';

export default {
  
  name: "resposta-audio-create-edit",
  props: {
    labelTitle: {
      type: String,
      default: 'Adicionar vídeos'
    },
    respostas: {
      type: Array,
      default: null
    },
    storageName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      arquivos: [],
      audioRespostas: [],
      arquivosUpload: [],
      isSelecting: false,
      audioRecording: null,
      mediaRecording: null,
      isRecording: false,
      isPausing: false,
      blobRecording: [],
      // iconAudioReposta: "mdi-motion-play-outline",
      iconAudioRecorder: "mdi-microphone-outline",
      // currentTime: null,
      // duration: null,
      timer: {
        timerId: null,
        initialDateTime: null,
        finalDateTime: null, 
        timeElapsed: "00:00:00",
      }
    };
  },
  components: {Alerta},
  computed: {
    getMediaDevices() {
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        return false;
      }
      return true;
    }
  },
  methods: {
    ...mapActions([
      'setAlerta',
    ]),    
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
          this.isSelecting = false
      }, { once: true });
      
      // Trigger click on the FileInput
      this.$refs.uploaderFile.click();
    },
    async onChangeAudioRespostas(e) {
      this.audioRespostas = Array.from(e.target.files);

      if(this.audioRespostas.length > 0) {
        let errors = []
        // Aguarda o processamento de todos os arquivos
        const arquivos = await Promise.all(
          this.audioRespostas.map(async (arquivo) => {
            
            // Verifica se o tamanho do arquivo é válido
            const isValid = await validateSizeFile(arquivo).catch(e => {
              errors.push(e.message)
              return null
            })
            // Em caso de exceção (catch) retorna null para ser filtrado
            if (!isValid) return null

            return new Promise((resolve) => {
              let reader = new FileReader();
              reader.readAsDataURL(arquivo);
              reader.onload = () => {
                resolve({ file: arquivo, url: reader.result })
              };    
            })
          })
        )
        // Cria alerta para informar que um ou mais arquivos não serão carregados
        if(errors.length > 0) alert(errors.map(msg => `* ${msg}\n`).join(""))

        // Adiciona os arquivos novos aos arquivos do componente filtrando os nulos
        this.arquivos.push(...arquivos.filter(arquivo => !!arquivo))
      }
    },
    deletarResposta(arquivo) {
      this.arquivos.splice(this.arquivos.indexOf(arquivo),1);
      var docs = Array.from(document.getElementsByTagName("audio"));
      docs.forEach(doc => {
        doc.load();
      })
    },
    closeAudioRecorder() {
      if(this.audioRecording != null) {
        this.audioRecording.srcObject.getTracks().forEach(track => track.stop());
      }
      this.iconAudioRecorder = "mdi-microphone-outline";
      this.stopTimer();
    },
    startPauseAudioRecord() {
      var self = this;
      if(this.mediaRecording == null || (this.mediaRecording != null && this.mediaRecording.state === "inactive")) {
        const constraints = {
          audio: true,
        };

        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          this.audioRecording = document.getElementById("audioRecord");
          this.audioRecording.srcObject = stream;

            this.mediaRecording = new MediaRecorder(this.audioRecording.srcObject, {mimeType: 'audio/webm'});
            this.blobRecording = [];
            this.isRecording = true;

            this.mediaRecording.ondataavailable = event => {
              this.blobRecording.push(event.data);
            }

            this.mediaRecording.onstop = () => {
              let blobUrl = window.URL.createObjectURL(new Blob(this.blobRecording, {type: "audio/mp3"}));        
              this.arquivos.push(Object.assign({file: new File(this.blobRecording, `MZ_audio_${Date.now()}.webm`, { type: 'audio/webm' }), url: blobUrl}))

              this.isRecording = false;
              this.isPausing = false;
              
              this.closeAudioRecorder();
            }

            this.mediaRecording.start(1000)
            this.iconAudioRecorder = "mdi-motion-pause-outline";
            this.resumeTimer();
        }).catch((error) => {            
          var textAlerta = ((error + "").indexOf('NotFoundError') > -1) ? `Dispositivo de audio não encontrado!` : `${error}`;
          self.setAlerta({
            tipoAlerta : 'error',
            textAlerta : `${textAlerta}`,
          });          
        });
      } else if (this.mediaRecording != null && this.mediaRecording.state === "recording") {
        this.pauseMyAudioRecord();
        this.pauseTimer();
      } else {
        this.continueMyAudioRecord()
        this.resumeTimer();
      }
    },
    stopMyAudioRecord() {
      if(this.mediaRecording != null && (this.mediaRecording.state === "recording" || this.mediaRecording.state === "paused")) {
        this.mediaRecording.stop();
      } else {
        this.closeAudioRecorder();
      }
    },
    pauseMyAudioRecord() {
      if(this.mediaRecording != null && this.mediaRecording.state === "recording") {
        this.mediaRecording.pause();
        this.isPausing = !this.isPausing;
        this.iconAudioRecorder = "mdi-microphone-outline";
      }
    },
    continueMyAudioRecord() {
      if(this.mediaRecording != null && this.mediaRecording.state === "paused") {
        this.mediaRecording.resume();
        this.isPausing = !this.isPausing;
        this.iconAudioRecorder = "mdi-motion-pause-outline";
      }
    },
    resumeTimer() {
      let period = 1000;

      if(this.timer.timerId == null) {
        this.timer.initialDateTime = new Date(new Date().getTimezoneOffset() * 60 * 1000);
        this.timer.finalDateTime = this.timer.initialDateTime;
      } else {
        clearInterval(this.timer.timerId);  
      }

      this.timer.timerId = setInterval(() => {
        this.timer.finalDateTime = new Date(this.timer.finalDateTime.valueOf() + period);
        
        this.timer.timeElapsed = this.timer.finalDateTime.toLocaleTimeString();
      }, period);
    },
    pauseTimer() {
      clearInterval(this.timer.timerId);
    },
    stopTimer() {
      clearInterval(this.timer.timerId);
      this.timer.timerId = null;
      this.timer.initialDateTime = null;
      this.timer.finalDateTime = null;
      this.timer.timeElapsed = "00:00:00"
    },
    audioControlsPlayPause(index) {
      var audioRespostaElement = document.getElementById(`audio_resposta_${index}`);

      if(audioRespostaElement != null) {

        if(audioRespostaElement.paused) {
          audioRespostaElement.play();
        } else {
          audioRespostaElement.pause();
        }

        // audioRespostaElement.onplay = e => {
        //   console.log("onplay: ", e);
        //   this.iconAudioReposta = "mdi-motion-pause-outline"
        // }
        // audioRespostaElement.onpause = e => {
        //   console.log("onpause: ", e);
        //   this.iconAudioReposta = "mdi-motion-play-outline"
        // }
        // audioRespostaElement.onended = e => {
        //   console.log("onended: ", e);
        // }
        // audioRespostaElement.addEventListener("durationchange", function (e) {
        //   console.log("durationChangeListener", e);
        // }, false); 

        // console.log("Played: ", audioRespostaElement.played);
        // console.log("Paused: ", audioRespostaElement.paused);
        // console.log("Ended: ", audioRespostaElement.ended);
        
        // console.log("currentTime: ", audioRespostaElement.currentTime);
        // console.log("Duration: ", audioRespostaElement.duration);
        // this.currentTime = audioRespostaElement.currentTime;
        // this.duration = audioRespostaElement.duration;
      }
    },
    getArquivosEmit() {
      if(Array.isArray(this.respostas)) {
        this.respostas.map(resp => ({...resp})).forEach(arquivo => {
          getFileStorage(arquivo, this.storageName)
            .then((response) => {
              let resposta = {file: new File([""], arquivo.originalname), url: response.data, _id: arquivo._id || null}

              if(this.arquivos.indexOf(resposta) < 0) {
                this.arquivos.push(resposta);
              }
            })
        });
      }
    },
    emitValue() {
      // Emite uma lista vazia caso não tenha respostas
      if (!this.arquivos || this.arquivos.length === 0) {
        this.$emit("returValue", []);
        return;
      }

      let respostas = [];
      if (this.respostas && this.respostas.length > 0) {
        // Filtra somente as respostas listadas na seleção do componente, mantendo o acesso ao storage
        respostas = this.respostas
          .filter(resp => this.arquivos.some(arq => arq._id === resp._id))
          .map(arq => ({ ...arq }))

        // Filtra somente os arquivos novos para upload
        const respostasFile = this.arquivos
          .filter(arquivo => !arquivo?._id)
          .map(arq => ({ ...arq }))
        
        respostas.push(...respostasFile)
      } else {
        respostas = this.arquivos.map(arquivo => ({ ...arquivo }));
      }

      this.$emit("returValue", respostas);
    },
  },
  watch: {
    arquivos() {
      this.emitValue();
    }
  },
  created() {
    if(this.respostas && this.respostas.length > 0 && this.storageName) {
      this.getArquivosEmit();
    }
  }
};
</script>

<style>
  .audio-resposta {
    width: 80%; 
    min-width: 210px;
    height: 30px; 
  }
</style>
<template>
  <div class="mr-1">
    <v-row no-gutters>
      <h5 class="pt-1">
        {{ labelTitle }}
        <span v-if="obrigatorio" style="color: red">*</span>
      </h5>

      <v-switch class="pl-3 py-0 ma-0"
        :label="resposta ? 'Sim' : 'Não'"
        :hint="labelSubTitle"
        @change="emitValue"
        v-model="resposta"
      ></v-switch>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "RespostaVerdadeiroFalsoCreateEdit",
  props: {
    labelTitle: {
      type: String,
      default: "",
    },
    labelSubTitle: {
      type: String,
      default: "",
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    respostas: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      resposta: false,
      rules: {
        campoObrigatorio: (v) => !!v || "Campo é obrigatório",
        textoObrigatorio: (v) => (v && !!v.trim()) || "Valor não pode ser branco",
        emailObrigatorio: (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        maxLenghtExceded: (v) => (v && v.length < this.maxLenght) || "Excedido limite de caracteres",
        selecaoObrigatoria: (v) => v.length > 0 || "Seleção Obrigatória",
      },
    };
  },
  methods: {
    emitValue() {
      var respostas;
      if(this.respostas) {
        respostas = { ...this.respostas }
        
        if(respostas.valor !== this.resposta) {
          respostas.dataCadastro = moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss");
        }
        respostas.valor = this.resposta
      } else {
        respostas = {
          dataCadastro: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss"),
          valor: this.resposta
        }
      }

      this.$emit("returValue", respostas);
    },
  },
  created() {
    if(this.respostas) {
      this.resposta = this.respostas.valor;
      this.emitValue();
    }
  }
};
</script>
<template>

  <v-snackbar
    :timeout="timeoutAlerta"
    :color="tipoAlerta"
    v-model="snackbar">
    {{ textAlerta }}
    <v-btn dark text @click.native="snackbar = false">Fechar</v-btn>
  </v-snackbar>

</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'alerta',
  data () {
    return {
      snackbar: false,
      tipoAlerta: '',
      timeoutAlerta: 6000,
      textAlerta: '',
    }
  },
  computed: {
    ...mapState([
      'alerta'
    ])
  },
  watch: {
    alerta (newValue, oldValue) {
      console.log('Updating from', oldValue, newValue);
      this.exibir(newValue)
    }
  },
  methods: {
    ...mapActions([
      'setAlerta',
    ]),
    exibir (params) {
      if (params.tipoAlerta) {
        this.tipoAlerta = params.tipoAlerta
        this.textAlerta = params.textAlerta
        this.snackbar = true 
        
        setTimeout(() => {
          this.setAlerta({})  
          this.snackbar = false
        }, 6500);
      }
    }
  },
  created () {
    if (this.alerta !== null) { 
      this.exibir(this.alerta)
    }
  }
}
</script>

<template>
  <v-row no-gutters v-if="answerOnly">
    <v-col>
      <div v-text="labelTitle"></div>
    </v-col>
    <v-col>
      <div v-if="texto" v-text="texto"></div>
    </v-col>
  </v-row>
  <div v-else class="mr-1">
    <v-row no-gutters>
      <h5>
        {{ labelTitle }}
        <span v-if="obrigatorio" style="color: red">*</span>
      </h5>
    </v-row>
    
    <v-row no-gutters>
      <v-text-field
        :rules="obrigatorio ? [rules.campoObrigatorio, rules.textoObrigatorio, rules.maxLenghtExceded] : []"
        :hint="labelSubTitle"
        :counter="maxLenght"
        @change="emitValue"
        v-model="texto"
        required
        outlined
        dense
      ></v-text-field>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "RespostaCurtaCreateEdit",
  props: {
    labelTitle: {
      type: String,
      default: "",
    },
    labelSubTitle: {
      type: String,
      default: "",
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    respostas: {
      type: Object,
      default: null,
    },
    answerOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      texto: "",
      maxLenght: 60,
      showObrigatorio: false,

      rules: {
        campoObrigatorio: (v) => !!v || "Campo é obrigatório",
        textoObrigatorio: (v) => (v && !!v.trim()) || "Valor não pode ser branco",
        emailObrigatorio: (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        maxLenghtExceded: (v) => (v && v.length < this.maxLenght) || "Excedido limite de caracteres",
        selecaoObrigatoria: (v) => v.length > 0 || "Seleção Obrigatória",
      },
    };
  },
  methods: {
    emitValue() {
      // Emite uma lista vazia caso não tenha respostas
      if(this.texto == null || (this.texto != null && this.texto.length == 0)) {
        this.$emit("returValue", null);
        return;
      }

      var respostas;
      if(this.respostas) {
        respostas = { ...this.respostas }
        
        if(respostas.valor !== this.texto) {
          respostas.dataCadastro = moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss");
        }
        respostas.valor = this.texto
      } else {
        respostas = { 
          dataCadastro: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss"),
          valor: this.texto 
        }
      }

      this.$emit("returValue", respostas);
    },
  },
  created() {
    if(this.respostas) {
      this.texto = this.respostas.valor;
      this.emitValue();
    }
  }
};
</script>

<template>
  <div class="mr-1">
    <v-row no-gutters align="center">
      <v-input v-if="showSelectionBoxes" hide-details="auto" dense :value="selected" :rules="obrigatorio ? [rules.selecaoObrigatoria] : []">
        <h5>
          <span class="mr-1">{{ labelTitle }}</span>
          <span class="mr-1" v-if="obrigatorio" style="color: red">*</span>
        </h5>
        <v-chip-group
          active-class="blue blue--text text--accent-4"
          @change="emitValue"
          v-model="selected"
          multiple
          column
        >
          <v-chip v-for="(item, i) in items" :key="i"
            :value="item"
            class="mr-1"
            outlined
            small
            label
            :ripple="false"
          >
            {{ item.valor }}
          </v-chip>
        </v-chip-group>
      </v-input>    
      <v-combobox v-else
        :rules="obrigatorio ? [rules.selecaoObrigatoria] : []"
        :placeholder="labelTitle"
        :label="labelTitle"
        :hint="labelSubTitle"
        @change="emitValue"
        item-text="valor"
        v-model="selected"
        :items="items"
        return-object
        clearable
        multiple
        item-id="ordem"
        outlined
        dense
      ></v-combobox>
    </v-row>
  </div>
</template>

<script>
import { compareObjects } from '../comum/Utils'

export default {
  name: "RespostaMultiplaCreateEdit",
  props: {
    labelTitle: {
      type: String,
      default: null,
    },
    labelSubTitle: {
      type: String,
      default: null,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      require: true,
      default: undefined,
    },
    respostas: {
      type: Array,
      default: null,
    },
    showSelectionBoxes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      oldSelected: [],

      rules: {
        campoObrigatorio: (v) => !!v || "Campo é obrigatório",
        textoObrigatorio: (v) => (v && !!v.trim()) || "Valor não pode ser branco",
        emailObrigatorio: (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        selecaoObrigatoria: (v) => v.length > 0 || "Seleção Obrigatória",
      },
    };
  },
  methods: {
    async emitValue() {
      // Emite uma lista vazia caso não tenha respostas
      if(this.selected == null || (this.selected != null && this.selected.length == 0)) {
        this.$emit("returValue", []);
        return;
      }

      let respostas = [];      
      this.selected.forEach(select => {
        respostas.push(select)
      });
      
      this.$emit("returValue", respostas);
    },
  },
  created() {
    if(this.respostas && this.respostas.length > 0) {
      this.selected = this.items.filter(item => this.respostas.filter(resposta => compareObjects(resposta, item)).length > 0)
      this.emitValue();
    }
  },
};
</script>
<template>
  <v-container fluid class="px-0">
    <v-card flat>
      <v-card-title class="primary white--text pa-2">
        <span class="font-weight-regular">{{ labelTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="handleFileImport" :loading="isSelecting" dark>
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
        <input 
          accept="image/png, image/jpeg, image/bmp"
          ref="uploaderFile" 
          v-show="false"
          type="file" 
          multiple
          @change="onChangeImagemRespostas"
        >
      </v-card-title>
      
      <!-- <v-toolbar dense dark color="primary" max-height="50">
        <v-toolbar-title>{{ labelTitle }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="handleFileImport" :loading="isSelecting" dark>
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
        <input 
          accept="image/png, image/jpeg, image/bmp"
          ref="uploaderFile" 
          v-show="false"
          type="file" 
          multiple
          @change="onChangeImagemRespostas"
        >
      </v-toolbar> -->

      <v-card-actions>
        <v-row class="v-row-list-imagens">
          <v-col md="3" v-for="(arquivo, idx) in arquivos" :key="getKeyIdx(idx, arquivo)">
            <v-card max-width="320">
              <v-card-text>
                <v-img :src="`${arquivo.url}`"
                  height="200"
                  contain
                ></v-img>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon @click="deletarResposta(arquivo)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>

                <v-btn icon @click="visualizarImagem(arquivo)">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- <div>
      <v-card flat>
        <v-card-actions>
          <v-row justify="center">
            <v-btn color="primary" @click="emitValue()">Confirmar Imagens Adicionadas</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </div> -->

    <v-dialog 
      v-model="dialogShowImage"
      scrollable
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-toolbar dense dark color="primary" max-height="50">
          <v-btn icon @click.native="dialogShowImage = false" dark>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ activeFileShowImage.name }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon :href="activeUrlShowImage">
            <v-icon>mdi-file-download</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-img :src="`${activeUrlShowImage}`" width="100%" height="100%" contain></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getFileStorage } from "@/utils/storageUtils"
import { validateSizeFile } from '../../utils/storageUtils';

export default {
  name: "resposta-imagem-create-edit",
  props: {
    labelTitle: {
      type: String,
      default: 'Adicionar imagens'
    },
    respostas: {
      type: Array,
      default: null
    },
    storageName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      arquivos: [],
      imagemRespostas: [],
      dialogShowImage: false,
      activeUrlShowImage: null,
      activeFileShowImage: new FileReader(),
      arquivosUpload: [],
      isSelecting: false,
    };
  },
  methods: {
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
          this.isSelecting = false
      }, { once: true });
      
      // Trigger click on the FileInput
      this.$refs.uploaderFile.click();
    },
    async onChangeImagemRespostas(e) {
      this.imagemRespostas = Array.from(e.target.files);

      if(this.imagemRespostas.length > 0) {
        let errors = []
        // Aguarda o processamento de todos os arquivos
        const arquivos = await Promise.all(
          this.imagemRespostas.map(async (imagem) => {
            
            // Verifica se o tamanho do arquivo é válido
            const isValid = await validateSizeFile(imagem).catch(e => {
              errors.push(e.message)
              return null
            })
            // Em caso de exceção (catch) retorna null para ser filtrado
            if (!isValid) return null

            return new Promise((resolve) => {
              let reader = new FileReader();
              reader.readAsDataURL(imagem);
              reader.onload = () => {
                resolve({ file: imagem, url: reader.result })
              };    
            })
          })
        )

        // Cria alerta para informar que um ou mais arquivos não serão carregados
        if(errors.length > 0) alert(errors.map(msg => `* ${msg}\n`).join(""))

        // Adiciona os arquivos novos aos arquivos do componente filtrando os nulos
        this.arquivos.push(...arquivos.filter(arquivo => !!arquivo))
      }
    },
    deletarResposta(arquivo) {
      this.arquivos.splice(this.arquivos.indexOf(arquivo),1);
    },
    visualizarImagem(arquivo) {
      this.dialogShowImage = true;
      this.activeUrlShowImage = arquivo.url;
      this.activeFileShowImage = arquivo.file;
    },
    getKeyIdx(idx, arquivo) {
      let id = arquivo._id ? `${arquivo._id}` : `${arquivo.url}`.length
      return `${id}_${idx}`
    },
    getArquivosEmit() {
      if(Array.isArray(this.respostas)) {
        this.respostas.map(resp => ({...resp})).forEach(arquivo => {
          getFileStorage(arquivo, this.storageName)
            .then((response) => {
              let resposta = {file: new File([""], arquivo.originalname), url: response.data, _id: arquivo._id || null}

              if(this.arquivos.indexOf(resposta) < 0) {
                this.arquivos.push(resposta);
              }
            })
        });
      }
    },
    emitValue() {
      // Emite uma lista vazia caso não tenha respostas
      if (!this.arquivos || this.arquivos.length === 0) {
        this.$emit("returValue", []);
        return;
      }

      let respostas = [];
      if (this.respostas && this.respostas.length > 0) {
        // Filtra somente as respostas listadas na seleção do componente, mantendo o acesso ao storage
        respostas = this.respostas
          .filter(resp => this.arquivos.some(arq => arq._id === resp._id))
          .map(arq => ({ ...arq }))

        // Filtra somente os arquivos novos para upload
        const respostasFile = this.arquivos
          .filter(arquivo => !arquivo?._id)
          .map(arq => ({ ...arq }))
        
        respostas.push(...respostasFile)
      } else {
        respostas = this.arquivos.map(arquivo => ({ ...arquivo }));
      }

      this.$emit("returValue", respostas);
    },
  },
  watch: {
    arquivos() {
      this.emitValue();
    }
  },
  created() {
    if(this.respostas && this.respostas.length > 0 && this.storageName) {
      this.getArquivosEmit();
    }
  }
};
</script>




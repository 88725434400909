
<template>
  <v-row no-gutters v-if="answerOnly">
    <v-col>
      <div v-text="labelTitle"></div>
    </v-col>
    <v-col>
      <v-chip-group v-if="showSelectionBoxes"
        active-class="blue blue--text text--accent-4"
        v-model="selected"
        column
      >
        <v-chip v-for="(item, i) in items" :key="i"
          :value="item"
          class="mr-1"
          outlined
          small
          label
          :ripple="false"
          style="pointer-events: none"
        >
          {{ item.valor }}
        </v-chip>
      </v-chip-group>
      <v-combobox v-else
        v-model="selected"
        :items="items"
        item-text="valor"
        itemid="ordem"
        append-icon=""
        outlined
        readonly
        dense
      >
        <template v-slot:selection="{attrs, item}">
          <v-chip
            v-bind="attrs"
            class="blue blue--text text--accent-4"
            style="pointer-events: none"
            outlined
            small
            label
          >{{ item.valor }}</v-chip>
        </template>
      </v-combobox>
    </v-col>
  </v-row>
  <div v-else class="mr-1">
    <v-row no-gutters align="center">
      <v-input v-if="showSelectionBoxes" hide-details="auto" dense :value="selected" :rules="obrigatorio && !selected ? [rules.campoObrigatorio] : []">
        <h5>
          <span class="mr-1">{{ labelTitle }}</span>
          <span class="mr-1" v-if="obrigatorio" style="color: red">*</span>
        </h5>
        <v-chip-group
          active-class="blue blue--text text--accent-4"
          @change="emitValue"
          v-model="selected"
          column
        >
          <v-chip v-for="(item, i) in items" :key="i"
            :value="item"
            class="mr-1"
            outlined
            small
            label
            :ripple="false"
          >
            {{ item.valor }}
          </v-chip>
        </v-chip-group>
      </v-input>
      <v-combobox v-else
        :rules="obrigatorio ? [rules.campoObrigatorio] : []"
        :placeholder="labelTitle"
        :label="labelTitle"
        :hint="labelSubTitle"
        @change="emitValue"
        item-text="valor"
        v-model="selected"
        :items="items"
        return-object
        clearable
        item-id="ordem"
        outlined
        dense   
        
      ></v-combobox>
    </v-row>
  </div>
</template>

<script>
import { compareObjects } from '../comum/Utils'

export default {
  name: "RespostaUnicaCreateEdit",
  props: {
    labelTitle: {
      type: String,
      default: "",
    },
    labelSubTitle: {
      type: String,
      default: undefined,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      require: true,
      default: undefined,
    },
    respostas: {
      type: Array,
      default: null
    },
    showSelectionBoxes: {
      type: Boolean,
      default: false,
    },
    answerOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
      
      rules: {
        campoObrigatorio: (v) => !!v || "Campo é obrigatório",
        textoObrigatorio: (v) => (v && !!v.trim()) || "Valor não pode ser branco",
        emailObrigatorio: (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        selecaoObrigatoria: (v) => v.length > 0 || "Seleção Obrigatória",
      },
    };
  },
  methods: {
    emitValue() {
      // Emite uma lista vazia caso não tenha respostas
      if(this.selected == null) {
        this.$emit("returValue", []);
        return;
      }

      let respostas = [];
      respostas.push(this.selected)
      this.$emit("returValue", respostas);
    },
  },
  created() {
    if(this.respostas && this.respostas.length > 0) {
      this.selected = this.items.filter(item => compareObjects(item, this.respostas[0]))[0];
      this.emitValue();
    }
  },
};
</script>
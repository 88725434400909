function compareObjects(obj1, obj2) {

  if (obj1 === obj2) return true

  for (let property in obj1) {
    if (obj1[property] != obj2[property]) {
      return false
    }
  }
  return true
}


export {
  compareObjects,
}

<template>
  <div class="mr-1">
    <v-row no-gutters>
      <h5>
        {{ labelTitle }}
        <span v-if="obrigatorio" style="color: red">*</span>
      </h5>
    </v-row>

    <v-row no-gutters>
      <v-dialog
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="auto"
        max-width="400"
        persistent
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :rules="obrigatorio ? [rules.campoObrigatorio] : []"
            prepend-inner-icon="mdi-calendar"
            :hint="labelSubTitle"
            v-model="dataText"
            outlined
            readonly
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="data"
          @change="emitValue"
        >
          <v-spacer></v-spacer>
            <v-btn text color="primary"
              @click="cancelarData"
            >
              Cancelar
            </v-btn>
            <v-btn text color="primary"
              @click="confirmarData"
            >
              Confirmar
            </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "RespostaDataCreateEdit",
  props: {
    labelTitle: {
      type: String,
      default: "",
    },
    labelSubTitle: {
      type: String,
      default: undefined,
    },
    obrigatorio: {
      type: Boolean,
      default: false,
    },
    respostas: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: "",
      dataText: "",
      menu: false,

      rules: {
        campoObrigatorio: (v) => !!v || "Campo é obrigatório",
        textoObrigatorio: (v) => (v && !!v.trim()) || "Valor não pode ser branco",
        emailObrigatorio: (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        maxLenghtExceded: (v) => (v && v.length < this.maxLenght) || "Excedido limite de caracteres",
        selecaoObrigatoria: (v) => v.length > 0 || "Seleção Obrigatória",
      },
    };
  },
  watch: {
    data() {
      if (this.data){
        this.dataText = moment(this.data, "YYYY-MM-DD").format("DD/MM/YYYY");    
      }                                
    },
  },
  methods: {
    emitValue() {
      // Emite uma lista vazia caso não tenha respostas
      if(this.data == null || (this.data != null && this.data.length == 0)) {
        this.$emit("returValue", null);
        return;
      }

      var respostas;
      if(this.respostas) {
        respostas = { ...this.respostas }
        
        if(respostas.valor !== this.data) {
          respostas.dataCadastro = moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss");
        }
        respostas.valor = this.data
      } else {
        respostas = {
          dataCadastro: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss"),
          valor: this.data
        }
      }

      this.$emit("returValue", respostas);
    },
    confirmarData() {
      this.menu = false;
      this.emitValue();
    },
    cancelarData() {
      this.menu = false;
      this.data = "";
      this.dataText = "";
      this.emitValue();
    }
  },
  created() {
    if(this.respostas) {
      this.data = this.respostas.valor;
      this.emitValue();
    }
  }
};
</script>
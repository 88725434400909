<template>
  <v-container fluid class="px-0">
    <v-card flat>
      <v-card-title class="primary white--text pa-2">
        <span class="font-weight-regular">{{ labelTitle }}</span>
        <v-spacer></v-spacer>
        <v-card v-if="getMediaDevices" color="blue-grey darken-1" height="95%" rounded="pill">
          <v-btn icon @click="handlerMyVideo" dark>
            <v-icon size="32">mdi-record-circle-outline</v-icon>
          </v-btn>
        </v-card>
        <v-btn icon @click="handleFileImport" :loading="isSelecting" dark>
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
        <input 
          accept="video/*"
          ref="uploaderFile" 
          v-show="false"
          type="file" 
          multiple
          @change="onChangeVideoRespostas"
        >
      </v-card-title>
      
      <!-- <v-toolbar dense dark color="primary" max-height="50">
        <v-toolbar-title>{{ labelTitle }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-card v-if="getMediaDevices" color="blue-grey darken-1" height="95%" rounded="pill">
          <v-btn icon @click="handlerMyVideo">
            <v-icon size="30">mdi-record-circle-outline</v-icon>
          </v-btn>
        </v-card>
        <v-btn icon @click="handleFileImport" :loading="isSelecting" dark>
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
        <input 
          accept="video/*"
          ref="uploaderFile" 
          v-show="false"
          type="file" 
          multiple
          @change="onChangeVideoRespostas"
        >
      </v-toolbar> -->
    
      <v-card-actions>
        <v-row class="v-row-list-videos">
          <v-col md="3" v-for="arquivo in arquivos" :key="arquivo.index">
            <v-card width="320">
              <v-card-text>
                <v-row justify="center">
                  <video id="video_resposta" width="85%" height="200" controls preload="auto">
                      <source id="video_resposta_source" :src="`${arquivo.url}`">
                  </video>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon @click="deletarResposta(arquivo)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>

                <v-btn icon @click="visualizarResposta(arquivo)">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- <div>
      <v-card flat>
        <v-card-actions>
          <v-row justify="center">
            <v-btn color="primary" @click="emitValue()">Confirmar Vídeos Adicionadas</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </div> -->

    <v-dialog 
      v-model="dialogShowVideo"
      scrollable
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-toolbar dense dark color="primary" max-height="50">
          <v-btn icon @click.native="closeVisualizarResposta()" dark>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ activeFileShowVideo.name }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon :href="activeUrlShowVideo">
            <v-icon>mdi-file-download</v-icon>
          </v-btn>
        </v-toolbar>
        
        <!-- <v-card-title>
          <v-row>
            <v-col>
              <span class="headline primary--text">
                Nome do arquivo: {{activeFileShowVideo.name}} 
              </span>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-btn @click="closeVisualizarResposta" color="primary">Fechar</v-btn>
        </v-card-title> -->

        <v-card-text>
          <video id="video_dialog" controls width="95%" height="95%"> 
            <source id="video_dialog_source" :src="`${activeUrlShowVideo}`">
          </video>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVideoRecord"
      scrollable
      fullscreen
    >
      <v-card flat>
        <v-toolbar dense dark color="primary" max-height="50">
          <v-btn icon @click.native="closeVideoRecorder()" dark>
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{ activeFileShowVideo.name }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon :href="activeUrlShowVideo">
            <v-icon>file_download</v-icon>
          </v-btn> -->
        </v-toolbar>

        <!-- <v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="closeVideoRecorder" color="primary">Fechar</v-btn>
        </v-card-title> -->
        
        <v-card-text>
          <v-row justify="center">
            <video id="videoRecord" autoplay muted width="100%" style="max-width: 600px"></video>
          </v-row>
          <v-row justify="center">
            <v-bottom-navigation width="100%" max-width="600px">
              <v-btn @click="startMyVideoRecord" :loading="isRecording" :disabled="isRecording">
                <span>Gravar</span>
                <v-icon>mdi-record-circle-outline</v-icon>
              </v-btn>

              <!-- <v-btn>
                <span>Pausar</span>
                <v-icon>mdi-pause-circle-outline</v-icon>
              </v-btn> -->

              <v-btn @click="stopMyVideoRecord">
                <span>Finalizar</span>
                <v-icon>mdi-stop-circle-outline</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </v-row>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <alerta></alerta>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Alerta from '@/components/comum/Alerta.vue'
import { getFileStorage } from "@/utils/storageUtils"
import { validateSizeFile } from '../../utils/storageUtils';

export default {
  name: "resposta-video-create-edit",
  props: {
    labelTitle: {
      type: String,
      default: 'Adicionar vídeos'
    },
    respostas: {
      type: Array,
      default: null
    },
    storageName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      arquivos: [],
      videoRespostas: [],
      dialogShowVideo: false,
      activeUrlShowVideo: "",
      activeFileShowVideo: new FileReader(),
      arquivosUpload: [],
      isSelecting: false,
      dialogVideoRecord: false,
      videoRecording: null,
      mediaRecording: null,
      isRecording: false,
      blobRecording: [],
    };
  },
  components: {Alerta},
  computed: {
    getMediaDevices() {
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        return false;
      }
      return true;
    }
  },
  methods: {
    ...mapActions([
      'setAlerta',
    ]),    
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener('focus', () => {
          this.isSelecting = false
      }, { once: true });
      
      // Trigger click on the FileInput
      this.$refs.uploaderFile.click();
    },
    async onChangeVideoRespostas(e) {
      this.videoRespostas = Array.from(e.target.files);

      if(this.videoRespostas.length > 0) {
        let errors = []
        // Aguarda o processamento de todos os arquivos
        const arquivos = await Promise.all(
          this.videoRespostas.map(async (arquivo) => {
            
            // Verifica se o tamanho do arquivo é válido
            const isValid = await validateSizeFile(arquivo).catch(e => {
              errors.push(e.message)
              return null
            })
            // Em caso de exceção (catch) retorna null para ser filtrado
            if (!isValid) return null

            return new Promise((resolve) => {
              let reader = new FileReader();
              reader.readAsDataURL(arquivo);
              reader.onload = () => {
                resolve({ file: arquivo, url: reader.result })
              };    
            })
          })
        )
        
        // Cria alerta para informar que um ou mais arquivos não serão carregados
        if(errors.length > 0) alert(errors.map(msg => `* ${msg}\n`).join(""))

        // Adiciona os arquivos novos aos arquivos do componente filtrando os nulos
        this.arquivos.push(...arquivos.filter(arquivo => !!arquivo))
      }
    },
    deletarResposta(arquivo) {
      this.arquivos.splice(this.arquivos.indexOf(arquivo),1);
      var docs = Array.from(document.getElementsByTagName("video"));
      docs.forEach(doc => {
        doc.load();
      })
    },
    visualizarResposta(arquivo) {
      this.dialogShowVideo = true;
      this.activeUrlShowVideo = arquivo.url;
      this.activeFileShowVideo = arquivo.file;

      var documentElement = document.getElementById("video_dialog");
      if(documentElement != null) {
        documentElement.load();
      }
    },
    closeVisualizarResposta() {
      this.dialogShowVideo = false;

      document.getElementById("video_dialog").pause();
    },
    closeVideoRecorder() {
      try{
        this.videoRecording.srcObject.getTracks().forEach(track => track.stop())
      }catch(e){
        //error
      }
      this.dialogVideoRecord = false;
    },
    handlerMyVideo() {
      var self = this;
      this.dialogVideoRecord = true;

      const constraints = {
        video: true,
        audio: true,
      };
      
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        this.videoRecording = document.getElementById("videoRecord");
        this.videoRecording.srcObject = stream;
      }).catch((error) => {            
          var textAlerta = ((error + "").indexOf('NotFoundError') > -1) ? `Dispositivo de video não encontrado!` : `${error}`;
          self.setAlerta({
            tipoAlerta : 'error',
            textAlerta : `${textAlerta}`,
          });          
      });
      
    },
    startMyVideoRecord() {
      this.mediaRecording = new MediaRecorder(this.videoRecording.srcObject, {mimeType: 'video/webm'});
      this.blobRecording = [];
      this.isRecording = true;

      this.mediaRecording.ondataavailable = event => {
        this.blobRecording.push(event.data);
      }

      this.mediaRecording.onstop = () => {
        let blobUrl = window.URL.createObjectURL(new Blob(this.blobRecording, {type: "video/mp4"}));        
        this.arquivos.push(Object.assign({file: new File(this.blobRecording, `MZ_video_${Date.now()}.webm`, { type: 'audio/webm' }), url: blobUrl}))

        this.isRecording = false;
        
        this.closeVideoRecorder();
      }

      this.mediaRecording.start(1000)
    },
    stopMyVideoRecord() {
      if(this.mediaRecording != null && (this.mediaRecording.state === "recording" || this.mediaRecording.state === "paused")) {
        this.mediaRecording.stop();
      } else {
        this.closeVideoRecorder();
      }
    },
    getArquivosEmit() {
      if(Array.isArray(this.respostas)) {
        this.respostas.map(resp => ({...resp})).forEach(arquivo => {
          getFileStorage(arquivo, this.storageName)
            .then((response) => {
              let resposta = {file: new File([""], arquivo.originalname), url: response.data, _id: arquivo._id || null}

              if(this.arquivos.indexOf(resposta) < 0) {
                this.arquivos.push(resposta);
              }
            })
        })
      }
    },
    emitValue() {
      // Emite uma lista vazia caso não tenha respostas
      if (!this.arquivos || this.arquivos.length === 0) {
        this.$emit("returValue", []);
        return;
      }

      let respostas = [];
      if (this.respostas && this.respostas.length > 0) {
        // Filtra somente as respostas listadas na seleção do componente, mantendo o acesso ao storage
        respostas = this.respostas
          .filter(resp => this.arquivos.some(arq => arq._id === resp._id))
          .map(arq => ({ ...arq }))

        // Filtra somente os arquivos novos para upload
        const respostasFile = this.arquivos
          .filter(arquivo => !arquivo?._id)
          .map(arq => ({ ...arq }))
        
        respostas.push(...respostasFile)
      } else {
        respostas = this.arquivos.map(arquivo => ({ ...arquivo }));
      }

      this.$emit("returValue", respostas);
    },
  },
  watch: {
    arquivos() {
      this.emitValue();
    }
  },
  created() {
    if(this.respostas && this.respostas.length > 0 && this.storageName) {
      this.getArquivosEmit();
    }
  }
};
</script>




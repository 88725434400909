
<template>
  <div class="mr-1">
    <v-row no-gutters>
      <h5>
        {{ labelTitle }}
      </h5>
    </v-row>
    <v-row no-gutters class="pt-1 mt-1">
      <div v-html="selected"></div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "resposta-legenda-create-edit",
  props: {
    labelTitle: {
      type: String,
      default: null,
    },
    labelSubTitle: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      require: true,
      default: null,
    }
  },
  data() {
    return {
      selected: ""
    };
  },
  methods: {},
  created() {
    if(this.items != null && this.items.length > 0) {
      this.selected = this.items[0].valor;
    }
  },
};
</script>